import { useState, useCallback } from 'react'

import styles from './booking-widget.module.scss'

import Icons from '@components/Icons'
import type { roomQty } from '@lib/types'


const BookingRoomsComponent = ({ roomQty, config, isPopup, setRoomQtyFunc, getRoomMinimalFunc }: {
    roomQty: roomQty[],
    config: any,
    isPopup: boolean,
    setRoomQtyFunc: (roomQty: roomQty[]) => void,
    getRoomMinimalFunc: () => roomQty
}) => {

    // Room dropdown open close
    const [focusedRoomSelector, setFocusedRoomSelector] = useState(false);

    const handleRoomToggle = useCallback((event: any) => {
        event.stopPropagation();
        setFocusedRoomSelector(!focusedRoomSelector);
    }, [setFocusedRoomSelector, focusedRoomSelector])

    //Room controler
    const changeRoomUp = useCallback(() => {
        setRoomQtyFunc([...roomQty, getRoomMinimalFunc()]);
    }, [roomQty, setRoomQtyFunc, getRoomMinimalFunc]);

    const changeRoomDown = useCallback(() => {
        setRoomQtyFunc(roomQty.filter((room) => {
            return room !== roomQty[roomQty.length - 1]
        }));
    }, [roomQty, setRoomQtyFunc]);


    // Guests Controller
    const setRoomGuests = useCallback((index: number, ppltype: any, direction: boolean) => {
        const theRoom: any = roomQty[index];
        theRoom[ppltype] = (direction ? theRoom[ppltype] + 1 : theRoom[ppltype] - 1);

        setRoomQtyFunc(
            roomQty.map((item, indexItem) => {
                return index === indexItem ? item : item;
            })
        )
    }, [roomQty, setRoomQtyFunc]);

    const changeRoomGuestsUp = useCallback((index: number, ppltype: any) => {
        setRoomGuests(index, ppltype, true);
    }, [setRoomGuests]);

    const changeRoomGuestsDown = useCallback((index: number, ppltype: any) => {
        setRoomGuests(index, ppltype, false);
    }, [setRoomGuests]);

    const getGuestsQty = useCallback(() => {
        let adults = 0;
        let children = 0;
        let infant = 0;

        roomQty.map((guest: any) => {
            adults = adults + guest.adults;
            children = children + guest.children;
            infant = infant + guest.infant;
        });

        const adultsTxt = `${adults} Adult${(adults > 1) ? "s" : ''}`;
        const childrenTxt = (children <= config.limits.guests.children.min) ? '' : `, ${children} Child${(children > 1) ? "ren" : ''}`;
        const infantTxt = (infant <= config.limits.guests.infant.min) ? '' : `, ${infant} Infant${(infant > 1) ? "s" : ''}`;

        return (`${adultsTxt} ${childrenTxt} ${infantTxt}`);
    }, [roomQty, config]);


    //the buttons render
    const qtyRender = useCallback((numRooms: number, funcUp: () => void, funcDowm: () => void, limits: { min: number, max: number }) => {
        return (
            <>
                <span className={styles.qty}>
                    <button
                        onClick={funcUp}
                        disabled={numRooms <= limits.min}
                    >
                        <Icons iconClass="edit-minus" extraClass="orange" />
                    </button>
                    <span>{numRooms}</span>
                    <button
                        onClick={funcDowm}
                        disabled={numRooms >= limits.max}
                    >
                        <Icons iconClass="edit-plus" extraClass="orange" />
                    </button>
                </span>
            </>
        )
    }, []);

    //the buttons render
    const qtyRenderGuests = useCallback((
        numAdults: number,
        numChildren: number,
        numInfant: number,
        index: number,
        limits: {
            //guest limits should be implemented here
            adults: {
                min: 1,
                max: 4
            },
            children: {
                min: 0,
                max: 3
            },
            infant: {
                min: 0,
                max: 3
            },
            maxguests: 4
        }
    ) => {
        const overallGuests = numAdults + numChildren + numInfant;
        return (
            <>
                <div className={styles.qtySelector}>
                    <span className={styles.title}>Adults</span>
                    <span className={styles.qty}>
                        <button
                            onClick={() => changeRoomGuestsDown(index, 'adults')}
                            disabled={numAdults <= limits.adults.min}
                        >
                            <Icons iconClass="edit-minus" extraClass="orange" />
                        </button>
                        <span>{numAdults}</span>
                        <button
                            onClick={() => changeRoomGuestsUp(index, 'adults')}
                            disabled={numAdults >= limits.adults.max || overallGuests >= limits.maxguests}
                        >
                            <Icons iconClass="edit-plus" extraClass="orange" />
                        </button>
                    </span>
                </div>
                <div className={styles.qtySelector}>
                    <span className={styles.title}>Children (2 - 16)</span>
                    <span className={styles.qty}>
                        <button
                            onClick={() => changeRoomGuestsDown(index, 'children')}
                            disabled={numChildren <= limits.children.min}
                        >
                            <Icons iconClass="edit-minus" extraClass="orange" />
                        </button>
                        <span>{numChildren}</span>
                        <button
                            onClick={() => changeRoomGuestsUp(index, 'children')}
                            disabled={numChildren >= limits.children.max || overallGuests >= limits.maxguests}
                        >
                            <Icons iconClass="edit-plus" extraClass="orange" />
                        </button>
                    </span>
                </div>
                <div className={styles.qtySelector}>
                    <span className={styles.title}>Infants (0 - 2)</span>
                    <span className={styles.qty}>
                        <button
                            onClick={() => changeRoomGuestsDown(index, 'infant')}
                            disabled={numInfant <= limits.infant.min}
                        >
                            <Icons iconClass="edit-minus" extraClass="orange" />
                        </button>
                        <span>{numInfant}</span>
                        <button
                            onClick={() => changeRoomGuestsUp(index, 'infant')}
                            disabled={numInfant >= limits.infant.max || overallGuests >= limits.maxguests}
                        >
                            <Icons iconClass="edit-plus" extraClass="orange" />
                        </button>
                    </span>
                </div>

            </>
        )
    }, [changeRoomGuestsDown, changeRoomGuestsUp]);


    return (
        <>
            <div
                className={`${styles.fieldWrapper} ${styles.fieldWrapperRoomGuests}`}
            >
                <div className={styles.label}>
                    Rooms/Guests
                </div>
                <div onClick={(e) => { handleRoomToggle(e) }} className={`${(focusedRoomSelector) ? styles.fieldWrapperActive : ''} ${styles.field} ${styles.input} rooms-guest`}>
                    <span>
                        <span>{roomQty.length} Rooms</span>
                        <span className={styles.separator}> | </span>
                        <span>{getGuestsQty()}</span>
                    </span>
                    <span className={styles.toggle}>
                        <Icons iconClass={`${(focusedRoomSelector) ? 'nav-arrow-up' : 'nav-arrow-down'}`} />
                    </span>
                </div>
                <div className={`${(focusedRoomSelector) ? styles.selectorOpen : styles.selectorClose} 
                    ${styles.selector}`}>
                    <div className={`${styles.wrapper} ${styles.margin}`}>
                        <div className={styles.qtySelector}>
                            <span className={styles.title}>Rooms Required</span>

                            {qtyRender(roomQty.length, changeRoomDown, changeRoomUp, config.limits.rooms)}

                        </div>
                        {Object.keys(roomQty).map((item, index) => (
                            <div className="roomsLooper" key={index}>
                                <div className={styles.roomTitle}>Room {config.numToWord[index]}</div>
                                {qtyRenderGuests(roomQty[index].adults, roomQty[index].children, roomQty[index].infant, index, config.limits.guests)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    )
}

export default BookingRoomsComponent;

/*                                <div className={styles.qtySelector}>
                                    <span className={styles.title}>Adults</span>
                                    {qtyRender(roomQty[index].adults, () => changeRoomGuestsDown(index, 'adults'), () => changeRoomGuestsUp(index, 'adults'), config.limits.guests.adults)}
                                </div>
                                <div className={styles.qtySelector}>
                                    <span className={styles.title}>Children</span>
                                    {qtyRender(roomQty[index].children, () => changeRoomGuestsDown(index, 'children'), () => changeRoomGuestsUp(index, 'children'), config.limits.guests.children)}
                                </div>
                                */