import { useCallback, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import styles from './booking-widget.module.scss'


const BookingCalendarV2 = ({dateRange, isPopup, setRangeFunc, currentMonth, dateRangeStrings} : {
    dateRange: DateRange | undefined,
    isPopup: boolean;
    setRangeFunc: (range: DateRange | undefined) => void,
    currentMonth: Date,
    dateRangeStrings: {
        from: string,
        to: string
    }
}) => {

    const [focusedInput, setFocusedInput] = useState(false);

    const handlePromoCodeToggle = useCallback((event: any) => {
        event.stopPropagation();
        setFocusedInput(!focusedInput);
    }, [focusedInput, setFocusedInput])

    return (
        <>
            <div 
                className={`${styles.fieldWrapper} ${styles.inputWrapper}`}
            >
                <div className={styles.fieldGroup}>
                    <div className={`${styles.field} ${styles.groupHalf}`} >
                        <div className={styles.label}>
                            From:
                        </div>
                        <div onClick={(e)=>{handlePromoCodeToggle(e)}} className={`${(focusedInput) ? styles.fieldWrapperActive : ''} ${styles.field} ${styles.input} rooms-guest`}>
                            <span className={styles.boxSize}>
                                {dateRangeStrings.from}
                            </span>                     
                        </div>
                    </div>
                    <div className={styles.fieldSeparator} />
                    <div className={`${styles.field} ${styles.groupHalf}`} >
                        <div className={styles.label}>
                            To:
                        </div>
                        <div onClick={(e)=>{handlePromoCodeToggle(e)}} className={`${(focusedInput) ? styles.fieldWrapperActive : ''} ${styles.field} ${styles.input} rooms-guest`}>
                            <span className={styles.boxSize}>
                                {dateRangeStrings.to}
                            </span>                    
                        </div>
                    </div>
                </div>
                
                <div className={`${(focusedInput) ? styles.selectorOpen : styles.selectorClose} 
                    ${styles.selector} ${styles.scaler}`}>
                    <div className={styles.wrapper}>
                        <DayPicker 
                            mode="range"
                            fromMonth={currentMonth}
                            selected={dateRange}
                            onSelect={setRangeFunc}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}


export default BookingCalendarV2;