import { useRef, useState, useCallback } from 'react'

import styles from './booking-widget.module.scss'

import Button from '@components/Button';

const BookingPromoCodeComponent = ({ promoCode, isPopup, setPromoCodeFunc } : {
    promoCode: string,
    isPopup: boolean,
    setPromoCodeFunc: (promo: string) => void
}) => {

    const [focusedPromoCode, setFocusedPromoCode] = useState(false);

    const inputText = useRef<HTMLInputElement>(null);

    const togglePromoCode = useCallback(() => {
        if (focusedPromoCode) {
            setPromoCodeFunc(inputText.current!.value);
        }
        setFocusedPromoCode(!focusedPromoCode);

    }, [inputText, focusedPromoCode, setPromoCodeFunc])
    
    return (
        <>
            <div 
                className={`${styles.fieldWrapper} ${styles.fieldWrapperPromoCode}`}
            >
                { (isPopup) ? 
                    <span className={`${styles.promoText}`} onClick={()=>{togglePromoCode()}}>Enter Promo Code</span>
                :
                <>
                <div className={styles.label}>
                    Promo Code:
                </div>
                <div onClick={()=>{togglePromoCode()}}className={`${(focusedPromoCode) ? styles.fieldWrapperActive : ''} ${styles.field} ${styles.input} rooms-guest`}>
                    <span>
                        { (promoCode == "" ) ? "xxx" : promoCode }
                    </span>
                    
                </div>
                </>
                }
                <div className={`${(focusedPromoCode) ? styles.selectorOpen : styles.selectorClose} 
                    ${styles.selector}`}>
                    <div className={styles.wrapper}>
                        <div className={styles.inputField}>
                            {!isPopup && <label>Enter Code</label>}
                            <input ref={inputText} type="text" maxLength={20} className={`${styles.input} ${styles.thin}`} />
                        </div>
                        <Button 
                            link={{text:"Apply",url:"#"}}
                            type="button"
                            extraClass="wider-center"
                            click={togglePromoCode}
                        ></Button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BookingPromoCodeComponent;