import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';

import styles from './booking-widget.module.scss'

import BookingRooms from './booking-widget-rooms';
import BookingPromoCodeComponent from './booking-widget-promocode';
import BookingCalendarV2 from './booking-widget-calendar-v2'

import Icons from '@components/Icons';
import Button from '@components/Button';
import type { roomQty } from '@lib/types';

const formatDate = (d: Date): string => {
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();
    return [year, month, day].join('-');
}

const addDays = (date: Date, days: number): Date => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

const urlParamsConfig = {
    hotel: "51603", //
    roomqty: [
        {
            adults: 1,
            children: 0,
            infant: 0
        }
    ],
    code: "",
    bookingUrl: "https://bookings.thecrocodilehunterlodge.com.au/rates-room1"
} // Example: ?hotel=5160&arrival=2022-04-28&departure=2022-04-29&rooms=2&adults[1]=2&adults[2]=1&children[1]=2&children[2]=3code=xyz

const roomConfig = {
    limits: {
        rooms: {
            min: 1,
            max: 7
        },
        guests: {
            //guest limits should be implemented here
            adults: {
                min: 1,
                max: 6
            },
            children: {
                min: 0,
                max: 4
            },
            infant: {
                min: 0,
                max: 2
            },
            maxguests: 6
        }
    },
    numToWord: ['one', 'two', 'three', 'four', 'five', 'six', 'seven']
}


const BookingComponent = () => {

    // Mobile Popup
    const [showMobileBooking, setShowMobileBooking] = useState<boolean>(false);

    const toggleShowMobileBooking = useCallback(() => {
        setShowMobileBooking(!showMobileBooking);
    }, [setShowMobileBooking, showMobileBooking]);

    const toggleShowMobileBookingBackground = useCallback((e) => {
        e.stopPropagation();
        if (e.target === e.currentTarget) {
            setShowMobileBooking(!showMobileBooking);
        }
    }, [setShowMobileBooking, showMobileBooking]);


    // URL of booking button
    const [bookingUrl, setBookingUrl] = useState(urlParamsConfig.bookingUrl);

    const [currentDate, setCurrentDate] = useState<Date>(new Date(0));

    //Calendar
    const [dateRange, setDateRange] = useState<DateRange | undefined>({
        from: new Date(0),
        to: addDays(new Date(0), 3)
    });

    const [dateRangeStrings, setDateRangeStrings] = useState({
        from: formatDate(new Date(0)),
        to: formatDate(addDays(new Date(0), 3))
    })


    // Room Quantity setup
    const getRoomMinimal = useCallback(() => {
        return {
            adults: roomConfig.limits.guests.adults.min,
            children: roomConfig.limits.guests.children.min,
            infant: roomConfig.limits.guests.infant.min
        }
    }, [])

    const [roomQty, setRoomQty] = useState([getRoomMinimal()]);


    // PromoCode setup
    const [promoCode, setPromoCode] = useState<string>("");


    // Convert URL params into an actual booking URL
    const convertToURL = useMemo(() => {

        let adultsString: string = ""; // initialise adults string
        let childrenString: string = ""; // initialise children string

        // populate adults and children arrays with room data
        roomQty.forEach((room: roomQty, index: number) => {
            const roomNum: any = index + 1;
            adultsString += `&adults%5B${roomNum}%5D=${room.adults.toString()}`;
            childrenString += `&children%5B${roomNum}%5D=${(room.children + room.infant).toString()}`;

            let childArrayNum: number = 1;
            for (let i = 0; i < room.children; i++) {
                childrenString += `&childAge%5B${roomNum}%5D%5B${childArrayNum}%5D=16`;
                childArrayNum++;
            }
            for (let i = 0; i < room.infant; i++) {
                childrenString += `&childAge%5B${roomNum}%5D%5B${childArrayNum}%5D=2`;
                childArrayNum++
            }
        })

        // Return full booking url with parameters
        return `${urlParamsConfig.bookingUrl}/?hotel=${urlParamsConfig.hotel}&arrival=${dateRangeStrings.from}&departure=${dateRangeStrings.to}&rooms=${roomQty.length}${adultsString}${childrenString}&codeType=promo&code=${promoCode}`;
        // https://bookings.thecrocodilehunterlodge.com.au/rates-room1?hotel=51603&arrival=2022-08-10&departure=2022-08-13&rooms=3&adults%5B1%5D=2&adults%5B2%5D=4&adults%5B3%5D=1&children%5B1%5D=2&childAge%5B1%5D%5B1%5D=16&childAge%5B1%5D%5B2%5D=16&children%5B2%5D=0&children%5B3%5D=3&childAge%5B3%5D%5B1%5D=16&childAge%5B3%5D%5B2%5D=16&childAge%5B3%5D%5B3%5D=16&codeType=promo&code=5
    }, [roomQty, promoCode, dateRangeStrings])

    useEffect(() => {
        setCurrentDate(new Date());
        setDateRange({
            from: new Date(),
            to: addDays(new Date(), 3)
        })
        setDateRangeStrings({
            from: formatDate(new Date()),
            to: formatDate(addDays(new Date(), 3))
        })
        setBookingUrl(convertToURL);
    }, [])

    useEffect(() => {
        setBookingUrl(convertToURL);
        setDateRangeStrings({
            from: (dateRange && dateRange.from) ? formatDate(dateRange!.from!) : "",
            to: (dateRange && dateRange.to) ? formatDate(dateRange!.to!) : ""
        })
    }, [convertToURL, dateRange])

    return (
        <>
            <div className={styles.bookingContainer}>
                {!showMobileBooking &&

                    <div className={` ${styles.bookingWrapper}  ${styles.showDesktop}`}>

                        <BookingCalendarV2 dateRange={dateRange} isPopup={false} setRangeFunc={(range) => { setDateRange(range) }} currentMonth={currentDate} dateRangeStrings={dateRangeStrings} />

                        <BookingRooms roomQty={roomQty} config={roomConfig} isPopup={false} setRoomQtyFunc={(qty) => { setRoomQty(qty) }} getRoomMinimalFunc={() => { return getRoomMinimal() }} />

                        <BookingPromoCodeComponent promoCode={promoCode} isPopup={false} setPromoCodeFunc={(promo: string) => { setPromoCode(promo) }} />

                        <div className={styles.fieldWrapper}>
                            <Button
                                link={{ text: "CHECK AVAILABILITY", url: bookingUrl }}
                                type="a"
                                extraClass='wider'
                            ></Button>
                        </div>
                    </div>
                }

                <div className={`${styles.fieldWrapper} ${styles.showMobile} ${styles.buttonMargin}`}>
                    <Button
                        link={{ text: "CHECK AVAILABILITY", url: "#" }}
                        type="button"
                        extraClass='wider'
                        click={() => { toggleShowMobileBooking() }}
                    ></Button>
                </div>
            </div>
            {showMobileBooking &&

                <div className={styles.bookingPopupBackground} onClick={(e) => { toggleShowMobileBookingBackground(e) }}>

                    <div className={styles.bookingPopup}>
                        <div className={styles.closeContainer}>
                            <button onClick={() => { toggleShowMobileBooking() }} aria-label="Close Booking" className={styles.closeButton} >
                                <span className={styles.promoText}>Close </span>
                                <Icons iconClass={`nav-x`} extraClass={styles.close} />
                            </button>
                        </div>

                        <BookingCalendarV2 dateRange={dateRange} isPopup={true} setRangeFunc={(range) => { setDateRange(range) }} currentMonth={currentDate} dateRangeStrings={dateRangeStrings} />

                        <BookingRooms roomQty={roomQty} config={roomConfig} isPopup={true} setRoomQtyFunc={(qty) => { setRoomQty(qty) }} getRoomMinimalFunc={() => { return getRoomMinimal() }} />

                        <BookingPromoCodeComponent promoCode={promoCode} isPopup={true} setPromoCodeFunc={(promo: string) => { setPromoCode(promo) }} />

                        <Button
                            link={{ text: "CHECK AVAILABILITY", url: bookingUrl }}
                            type="a"
                            extraClass='wider'
                        ></Button>
                    </div>

                </div>
            }

        </>
    )
}

export default BookingComponent;