import Link from 'next/link'
import Styles from './button.module.scss'
import type { btnlink } from '@lib/types'
import { useCallback } from 'react'



// Creates button and takes btnlink data type as values
const Button = ({ link, type = "a", extraClass, click, target_blank = false }: {
    link: btnlink,
    type?: string,
    extraClass?: string,
    click?: () => void,
    target_blank?: boolean
}) => {

    const renderButtons = useCallback((link: btnlink, type: string, extraClass?: string, click?: () => void) => {

        let classes = Styles.btn;
        if (extraClass) {
            classes = `${classes} ${Styles[extraClass]}`
        }
        switch (type) {
            case 'button':
                return (<button data-testid="btn" onClick={() => { (click && click()) }} className={classes} >{link.text}</button>)
            default:
                return (
                    <>
                        <Link
                            href={link.url}
                            data-testid="btn"
                            className={classes}
                            style={{ color: '#fff !important' }}
                            onClick={() => { (click && click()) }}
                            target={(target_blank) ? "_blank" : "_self"}>
                            {link.text}
                        </Link>
                    </>
                );
        }
    }, [target_blank])

    return (
        renderButtons(link, type, extraClass, click)
    )
}
export default Button;